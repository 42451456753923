var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("c-table", {
            ref: "table",
            attrs: {
              title: "개선 목록",
              tableId: "impr01",
              columns: _vm.gridImpr.columns,
              data: _vm.riskReduce.imprs,
              merge: _vm.gridImpr.merge,
              gridHeight: _vm.setheight,
            },
            on: { linkClick: _vm.linkClick },
            scopedSlots: _vm._u([
              {
                key: "customArea",
                fn: function ({ props, col }) {
                  return [
                    col.name === "riskHazardName"
                      ? [
                          _vm._v(" " + _vm._s(props.row[col.name]) + " "),
                          _c("q-btn", {
                            attrs: {
                              unelevated: "",
                              round: "",
                              dense: "",
                              size: "10px",
                              color: "primary",
                              icon: "wallpaper",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.openHazardPicture(props)
                              },
                            },
                          }),
                        ]
                      : col.name === "picture"
                      ? [
                          _c("q-btn", {
                            staticClass: "tableinnerBtn",
                            attrs: {
                              flat: "",
                              align: "center",
                              color: "blue-6",
                              label: "",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.openImprPicture(props)
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function () {
                                    return [
                                      props.row.editFlag !== "C"
                                        ? [
                                            _c("q-btn", {
                                              attrs: {
                                                unelevated: "",
                                                round: "",
                                                dense: "",
                                                size: "10px",
                                                color: "primary",
                                                icon: "wallpaper",
                                              },
                                            }),
                                          ]
                                        : _vm._e(),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }